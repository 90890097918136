import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "sf-farmcomp" }
const _hoisted_2 = { class: "sf-farmcomp-wrapper" }
const _hoisted_3 = { class: "sf-farmcomp-content" }
const _hoisted_4 = { class: "sf-farmcomp-heading" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "sf-farmcomp-logo" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "sf-farmcomp-map" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "sf-farmcomp-para" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "sf-farmcomp-imgblock" }
const _hoisted_14 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.title !== null)
            ? (_openBlock(), _createElementBlock("h3", {
                key: 0,
                class: "title align-left",
                innerHTML: _ctx.title
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          (_ctx.subtitle !== null)
            ? (_openBlock(), _createElementBlock("h4", {
                key: 1,
                class: "subtitle align-left",
                style: _normalizeStyle(`color:${_ctx.subColor};`),
                innerHTML: _ctx.subtitle
              }, null, 12, _hoisted_6))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.logo !== null)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: require(`@/assets/brand/${_ctx.logo}`)
              }, null, 8, _hoisted_8))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.map !== null)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: require(`@/assets/farmmap/${_ctx.map}`)
              }, null, 8, _hoisted_10))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paragraph, (p) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: p }, [
              (p !== null)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "align-left",
                    innerHTML: p
                  }, null, 8, _hoisted_12))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (img) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: img }, [
            (_ctx.images !== null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: require(`@/assets/images/${img}`)
                }, null, 8, _hoisted_14))
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        (_ctx.link !== null)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}