import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./theme/defaults.scss";
import "./theme/defaultColors.css";
import "./theme/custom.scss";
import "./theme/customColors.css";
import "./theme/structure.scss";

import FlexBoxLeft from "@/components/structure/FlexBoxLeft.vue";
import FlexBoxRight from "@/components/structure/FlexBoxRight.vue";
import CenterFullWidth from "@/components/structure/CenterFullWidth.vue";
import TwoCol from "@/components/structure/Two-Col.vue";
import ImgFiveAcross from "@/components/structure/ImgFiveAcross.vue";
import SixtyForty from "@/components/structure/SixtyForty.vue";
import PageHeaderTwoCol from "@/components/structure/PageHeaderTwoCol.vue";

import FarmComponent from "@/components/structure/FarmComponent.vue";

/*
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faHome,
  faMobileAlt,
  faSeedling,
  faEnvelope,
  faInfoCircle,
  faChevronRight,
  faMarker,
} from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faBars,
  faHome,
  faMobileAlt,
  faSeedling,
  faEnvelope,
  faInfoCircle,
  faChevronRight,
  faMarker
);*/

createApp(App)
  .use(store)
  .use(router)
  .component("FlexBoxLeft", FlexBoxLeft)
  .component("FlexBoxRight", FlexBoxRight)
  .component("CenterFullWidth", CenterFullWidth)
  .component("TwoCol", TwoCol)
  .component("ImgFiveAcross", ImgFiveAcross)
  .component("SixtyForty", SixtyForty)
  .component("FarmComponent", FarmComponent)
  .component("PageHeaderTwoCol", PageHeaderTwoCol)
  //.component("fai", FontAwesomeIcon)
  .mount("#app");
