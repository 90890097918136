<template>
  <div id="mission-wrapper">
    <div id="mission">
      <h3 class="alt-title align-right">Our Mission</h3>
      <div class="message">
        <p>
          To support a complete lifestyle encompassing mental, spiritual, and
          physical wellbeing through Food is Medicine, Education, Specialized
          Training, and Research and Development.
        </p>
        <router-link to="/about"
          ><button class="main-button">Discover</button></router-link
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#mission-wrapper {
  display: flex;
  width: 100%;
  height: auto;
  padding: 50px 0;
  background: var(--green);
}
#mission {
  width: 100%;
  max-width: 1050px;
  margin: auto;
  display: flex;
  width: 100%;
  height: auto;

  background: var(--green);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);

  .message {
    width: 60%;
    margin: auto;
  }

  button {
    margin: 20px;
  }

  @media (min-width: 900px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: left;
    padding: 0 30px;

    .message {
      padding: 0 0 0 50px;
    }

    p {
      text-align: left;
    }
    button {
      display: block;
      margin: 20px 10px;
    }
  }
}
</style>
