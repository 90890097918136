
import { defineComponent } from "vue";
import Hero from "@/components/Hero.vue";
import Mission from "@/components/Mission.vue";
import FarmMap from "@/components/FARMacy/FarmMap.vue";
import AdOne from "@/components/ads/FARMacy_Ad.vue";
import BrandSegments from "@/components/FARMacy/BrandSegments.vue";

export default defineComponent({
  components: {
    Hero,
    Mission,
    FarmMap,
    AdOne,
    BrandSegments,
  },
});
